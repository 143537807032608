<template>
  <div class="record">
    <div class="record_box margin-auto">
      <div class="business_tag flexs p-l-100">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/microlecture?type=1' }">微课堂</el-breadcrumb-item>
          <el-breadcrumb-item>往期考试记录</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="record_main b-c-f">
        <div class="record_head">
          <span>往期考试记录</span>
        </div>
        <ul class="record_list">
          <li class="flex" v-for="(item, index) in recordList" :key="index">
            <div class="record_list_left">
              <div class="record_list_name">{{ item.title }}</div>
              <div class="record_list_time">{{ item.createtime }}</div>
            </div>
            <div class="record_list_fen flexs">
              <span>成绩：</span>
              <span>{{ item.defen }}</span>
            </div>
          </li>
        </ul>
        <div class="paging" v-if="recordList.length">
          <el-pagination
            background
            @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page:1,
      recordList: [], //记录列表
      total: 0, //总条数
    };
  },
  methods: {
    changePage (e) {
      this.page = e
      this.getKaoShiList()
    },
    getKaoShiList() {
      this.$axios.kaoShiList({page:this.page}).then((res) => {
        this.recordList = res.data.data;
        this.total = res.data.total;
      });
    },
  },
  created() {
    this.getKaoShiList();
  },
};
</script>

<style lang="less" scoped>
.record_box {
  max-width: 1400px;
  .record_head {
    padding-left: 14px;
    span {
      color: #333333;
      font-size: 18px;
      position: relative;
      &::before {
        content: '';
        left: -14px;
        top: 50%;
        width: 4px;
        height: 20px;
        background: #3273F6;
        transform: translateY(-50%);
        position: absolute;
      }
    }
  }
  .record_main {
    padding: 30px 100px;
    .record_list {
      li {
        padding: 20px 0;
        border-bottom: 1px solid #cccccc;
        .record_list_name {
          color: #333333;
          font-size: 18px;
          margin-bottom: 10px;
        }
        .record_list_time {
          color: #666666;
          font-size: 16px;
        }
        .record_list_fen {
          span {
            color: #666666;
            font-size: 16px;
            &:last-child {
              color: #ff2121;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
